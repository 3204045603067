import React from "react"
import { MT5MobileQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  HeadingBanner,
  SubscriptionPanel,
  QuickStats,
  PrismicRichText,
} from "../../components/shared"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    page_heading,
    main_buttons,

    download_box,
    content_body,
    content_heading,
    stats_items,
    stats_heading,
  } = MT5MobileQuery(language)
  return (
    <>
      <Layout>
        <HeadingBanner
          heading={page_heading}
          banner_image={banner_image}
          buttons={main_buttons}
        />
        <QuickStats heading={stats_heading} items={stats_items} />
        <section className="job-contents p-top-20 p-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>{content_heading[0].text}</h3>
                <PrismicRichText data={content_body} />
                <div className="hr-divider2"></div>

                {download_box.map((item, itemInd) => (
                  <div key={'sdffhgd'+itemInd}>
                    <h3> {item.download_title[0].text} </h3>
                    <div className="m-top-25">
                      <div className="row col-sm-12">
                        <div className="col-sm-7">
                          <PrismicRichText data={item.download_content} />
                        </div>
                        <div
                          className="col-sm-5"
                          style={{ textAlign: "right" }}
                        >
                          <img
                            alt="icon"
                            src={item.download_picture.url}
                            className="store-icons"
                          />
                        </div>
                      </div>
                      <br />
                      <p className="">
                        <a
                          href={item.download_link[0].text}
                          className="btn  rounded-0 btn-icon icon-right"
                          style={{ backgroundColor: "#1175bb", color: "#fff" }}
                          target="_blank" rel="noreferrer"
                        >
                          {item.download_title[0].text}{" "}
                          <span className="la la-download"></span>
                        </a>
                      </p>
                    </div>
                    <div className="hr-divider2"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
